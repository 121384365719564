// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '@github-ui/onfocus'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'

on('click', '.js-repo-change-visibility-button', function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const modal = document.getElementById(dialogId) as HTMLElement

  modal.addEventListener(
    'cancel',
    () => {
      resetVisibilityModal(modal)
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetVisibilityModal(modal)
    },
    {once: true},
  )
})

on('click', '.js-repo-detach-button', function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const modal = document.getElementById(dialogId) as HTMLElement

  modal.addEventListener(
    'cancel',
    () => {
      resetDetachModal()
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetDetachModal()
    },
    {once: true},
  )
})

on('click', '.js-repo-detach-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!

  if (nextStage) {
    transitionDetachModalTo(nextStage)
  }
})

onInput('.js-repo-detach-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (repoNwo) {
    const button = document.getElementById(`repo-detach-proceed-button`) as HTMLButtonElement
    const proceed = input.value === repoNwo

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

function transitionDetachModalTo(stage: string) {
  const replacementWarningTemplate = document.getElementById(`detach-repository-step-${stage}`) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    `proceed-button-detach-step-${stage}`,
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(`repo-detach-warning-container`) as HTMLDivElement
    const existingButtonContainer = document.getElementById(`repo-detach-proceed-button-container`) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.textContent = ''
      existingButtonContainer.textContent = ''
      warningContainer.appendChild(replacementWarningTemplateClone)
      existingButtonContainer.appendChild(replacementButtonTemplateClone)
    }
  }

  const warningContent = document.querySelector('[aria-label="Effects of detaching this fork"]') as HTMLDivElement
  warningContent?.focus()
}

function resetDetachModal() {
  transitionDetachModalTo('2')
}

on('click', '.js-repo-delete-button', async function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const actionAllowedUrl = currentTarget.getAttribute('data-repository-action-allowed-url')!
  const modal = document.getElementById(dialogId) as HTMLElement

  const bypassButton = document.getElementById('repo-delete-delegated-button') as HTMLElement

  const response = await verifiedFetchJSON(actionAllowedUrl)
  const {error, isAllowed, createBypassRequestUrl, bypassButtonText} = await response.json()

  if (isAllowed || error) {
    transitionDeleteModalTo('1')
  }

  if (!isAllowed && createBypassRequestUrl) {
    const replacementWarningTemplate = document.getElementById(`delete-repository-step-2`) as HTMLTemplateElement
    const warningContainer = document.getElementById(`repo-delete-warning-container`) as HTMLDivElement

    const buttonWrapperLink = document.createElement('a')
    buttonWrapperLink.setAttribute('href', createBypassRequestUrl)
    const newButton = bypassButton.cloneNode(true) as HTMLButtonElement
    newButton.removeAttribute('disabled')
    const bypassButtonTextElement = newButton.querySelector('.Button-label') as HTMLButtonElement
    bypassButtonTextElement.textContent = bypassButtonText
    buttonWrapperLink.appendChild(newButton)
    bypassButton.parentNode?.replaceChild(buttonWrapperLink, bypassButton)
    warningContainer.appendChild(replacementWarningTemplate.content.cloneNode(true))
  }

  modal.addEventListener(
    'cancel',
    () => {
      resetDeleteModal(!!actionAllowedUrl)
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetDeleteModal(!!actionAllowedUrl)
    },
    {once: true},
  )
})

on('click', '.js-repo-visibility-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!
  const newVisibility = button.getAttribute('data-new-visibility')!

  if (nextStage && newVisibility) {
    transitionVisibilityModalTo(newVisibility, nextStage)
  }
})

on('click', '.js-repo-delete-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!

  if (nextStage) {
    transitionDeleteModalTo(nextStage)
  }
})

onInput('.js-repo-visibility-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const numberOfStars = parseInt(input.getAttribute('data-number-of-stars')!, 10)
  const newVisibility = input.getAttribute('data-new-visibility')!
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (newVisibility && repoNwo) {
    const button = document.getElementById(`repo-visibility-proceed-button-${newVisibility}`) as HTMLButtonElement

    let proceed = false

    if (newVisibility === 'public') {
      proceed = input.value === repoNwo
    } else {
      proceed = numberOfStars === parseInt(input.value.replace(/[^0-9]+/g, ''), 10)
    }

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'visibility', newVisibility)
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

onInput('.js-repo-delete-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (repoNwo) {
    const button = document.getElementById(`repo-delete-proceed-button`) as HTMLButtonElement
    const proceed = input.value === repoNwo

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

function resetVisibilityModal(modal: HTMLElement) {
  const newVisibility = modal.getAttribute('data-new-visibility')!

  if (newVisibility) {
    transitionVisibilityModalTo(newVisibility, '1')
  }
}

function resetDeleteModal(bypassRequestEnabled: boolean) {
  transitionDeleteModalTo('1', {bypassRequestEnabled})
}

function addHiddenFormInput(form: HTMLFormElement, name: string, value: string) {
  const newInput = document.createElement('input')
  newInput.setAttribute('type', 'hidden')
  newInput.setAttribute('name', name)
  newInput.setAttribute('value', value)

  form.appendChild(newInput)
}

function transitionVisibilityModalTo(newVisibility: string, stage: string) {
  const replacementWarningTemplate = document.getElementById(
    `change-repository-visibility-${newVisibility}-step-${stage}`,
  ) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    `proceed-button-visibility-${newVisibility}-step-${stage}`,
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(
      `repo-visibility-warning-${newVisibility}-container`,
    ) as HTMLDivElement
    const existingButtonContainer = document.getElementById(
      `repo-visibility-proceed-button-${newVisibility}-container`,
    ) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.replaceWith(replacementWarningTemplateClone)
      existingButtonContainer.replaceWith(replacementButtonTemplateClone)
    }

    const warningContent = document.querySelector(
      `[aria-label="Effects of making this repository ${newVisibility}"]`,
    ) as HTMLDivElement
    warningContent?.focus()
  }
}

function transitionDeleteModalTo(stage: string, {bypassRequestEnabled}: {bypassRequestEnabled?: boolean} = {}) {
  const replacementWarningTemplate = document.getElementById(`delete-repository-step-${stage}`) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    !bypassRequestEnabled ? `proceed-button-delete-step-${stage}` : 'bypass-button-delete',
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(`repo-delete-warning-container`) as HTMLDivElement
    const existingButtonContainer = document.getElementById(`repo-delete-proceed-button-container`) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.textContent = ''
      existingButtonContainer.textContent = ''
      warningContainer.appendChild(replacementWarningTemplateClone)
      existingButtonContainer.appendChild(replacementButtonTemplateClone)
    }
  }

  const warningContent = document.querySelector('[aria-label="Effects of deleting this repository"]') as HTMLDivElement
  warningContent?.focus()
}
