import {controller, target} from '@github/catalyst'

@controller
class ActionsPolicyFormElement extends HTMLElement {
  @target declare specificOptions: HTMLElement
  @target declare selectRadio: HTMLInputElement

  connectedCallback() {
    // In case user refreshes the page and the form saved state in Firefox
    this.toggleSpecificOptions()
  }

  toggleSpecificOptions() {
    if (this.selectRadio.checked) {
      this.specificOptions.hidden = false
    } else {
      this.specificOptions.hidden = true
    }
  }
}
