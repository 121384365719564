// PATs v2: CAP Access check behavior

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'
import {updateExpiration} from './personal-access-token-summary'
import {isFeatureEnabled} from '@github-ui/feature-flags'

const AuthorizationState = {
  Checking: '.js-cap-checking',
  RetryChecking: '.js-cap-retry-checking',
  Resolved: '.js-cap-resolved',
  Forbidden: '.js-cap-forbidden',
} as const

type AuthorizationState = (typeof AuthorizationState)[keyof typeof AuthorizationState]

async function verifyAuthorization(event: CustomEvent) {
  displayAuthorizationSectionFor(AuthorizationState.Checking)

  const relatedTarget = isFeatureEnabled('fgpat_form_ui_updates')
    ? (event.detail.item as HTMLLIElement)
    : (event.detail.relatedTarget as HTMLElement)

  const src = relatedTarget.getAttribute('data-src')

  if (src === null) {
    throw new Error('Target source location not found')
  }

  const url = new URL(src, window.location.origin)

  const request = new Request(url.toString(), {
    headers: {Accept: 'text/html', 'X-Requested-With': 'XMLHttpRequest'},
  })

  let response

  try {
    response = await fetch(request)
  } catch {
    // Ignore network errors
  }

  if (!response) {
    displayAuthorizationSectionFor(AuthorizationState.Forbidden)
  } else if (response.status === 403) {
    displayAuthorizationSectionFor(AuthorizationState.Forbidden)
  } else if (!response.ok) {
    displayAuthorizationSectionFor(AuthorizationState.RetryChecking)
  } else {
    const resolvedSection = document.querySelector<HTMLElement>(AuthorizationState.Resolved)!

    resolvedSection.textContent = ''
    resolvedSection.appendChild(parseHTML(document, await response.text()))

    displayAuthorizationSectionFor(AuthorizationState.Resolved)
  }
}

function displayAuthorizationSectionFor(selector: AuthorizationState): HTMLElement {
  for (const sectionSelector of Object.values(AuthorizationState)) {
    const section = document.querySelector<HTMLElement>(sectionSelector)!
    section.hidden = true
  }

  const section = document.querySelector<HTMLElement>(selector)!
  updateExpiration()
  section.hidden = false

  return section
}

on('click', '#js-retry-cap-check', () => {
  if (isFeatureEnabled('fgpat_form_ui_updates')) {
    const selectPanel = document.querySelector<HTMLElement>('#resource-owner-select-panel')!
    const selected = document.querySelector<HTMLLIElement>('#resource-owner-select-panel-list li[aria-selected=true]')!

    selectPanel.dispatchEvent(
      new CustomEvent('itemActivated', {
        bubbles: true,
        detail: {item: selected, checked: true},
      }),
    )
  } else {
    const checked = document.querySelector<HTMLElement>('.js-target-container input[type=radio]:checked')!
    const menu = checked.closest<HTMLElement>('details-menu')!

    const selected = checked.parentElement as HTMLElement
    if (!(selected instanceof HTMLElement)) return

    // Replicate the current target being selected again
    menu.dispatchEvent(
      new CustomEvent('details-menu-selected', {
        detail: {relatedTarget: selected},
      }),
    )
  }
})

on('details-menu-selected', '.js-target-container', verifyAuthorization, {capture: true})
on('itemActivated', '#resource-owner-select-panel', verifyAuthorization, {capture: true})
